.add-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.add-cart h1 {
  margin-bottom: 1rem;
}

.add-cart form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-cart .product-input {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}

.add-cart input {
  margin-left: 0.7rem;
  width: 70px;
  font-size: 20px;
  text-align: center;
}

.add-cart .error {
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .add-cart {
    padding: 0;
  }
  .add-cart label {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .add-cart input {
    margin: 0;
    margin-top: .5rem;
  }
}
