.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 2rem;
}
@media (max-width: 768px) {
  .cart-details {
    width: 100%;
  }
  .cart-details .recharts-wrapper {
    overflow-x: scroll;
  }
}
