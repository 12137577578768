.cart-list {
  margin-top: 2rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.grid-item {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
}

.grid-item p {
  margin: 0.6rem 0;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 21px;
}
