:root {
  --primary-color: #2450a9;
  --primary-color-hover: #4168c4;
}

body {
  margin: 0;
  font-family: "Cantarell", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buttons-row {
  display: flex;
  justify-content: center;
  gap: 1rem;
  height: 30px;
}

.contained {
  border: none;
  background-color: var(--primary-color);
  color: white;
}

.contained:hover {
  background-color: var(--primary-color-hover);
}

.outlined {
  border: solid 3px var(--primary-color);
  background-color: transparent;
}

.outlined:hover {
  border-color: var(--primary-color-hover);
}

.text {
  border: 0;
  background-color: transparent;
  color: var(--primary-color);
}

.error {
  color: red;
}

.button {
  padding: 6px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.button:disabled {
  opacity: 0.38;
  cursor: unset;
}

.MuiCircularProgress-root {
  height: 30px !important;
  width: 30px !important;
}
